import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import ContactForm from "../components/blocks/ContactForm";
import Anchor from "../components/elements/Anchor";

import { constructActiveDoc } from "../utilities";
import config from "../../config/site-config";
import "../styles/pages/contact.scss";

const Contact = ({ data }) => {
  if (!data) return null;
  const { settings, page } = data;
  const {
    address_street: street,
    address_postal_code: postalCode,
    address_region: region,
    address_city: city,
    address_country: country,
    address_link: addressLink,
    email,
    phone,
  } = settings.data;

  const {
    title,
    image,
    image_mobile: imageMobile,
    header_form: headerForm,
    header_location: headerLocation,
    image_location: imageLocation,
    image_mobile_location: imageMobileLocation,
  } = page.data;

  const homepageUrl =
    settings.lang === config.siteLang
      ? "/"
      : `/${settings.lang.split("-")[0]}/`;

  return (
    <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
      <HeaderBackground title={title} image={image} imageMobile={imageMobile} />
      <Container className="contact-make text-center text-lg-start">
        <Row>
          <Col lg="3" className="mb-5">
            <div className="decorated-header">
              <PrismicRichText field={headerForm.richText} />
            </div>
          </Col>
          <Col lg="6" className="mb-5">
            <ContactForm settings={settings} />
          </Col>
          <Col lg="3" className="vstack gap-4 mb-5 basic-text">
            <div>
              <h3 className="basic-text fw-bold">Address:</h3>
              <Anchor href={addressLink.url} target={addressLink.target}>
                {street} <br />
                {postalCode}, {region} <br />
                {city}, {country}
              </Anchor>
            </div>
            <div>
              <h3 className="h5 fw-bold">Tel:</h3>
              <a href={`tel:${phone.replaceAll(" ", "")}`}>{phone}</a>
            </div>
            <div>
              <h3 className="basic-text fw-bold">E-mail:</h3>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <div>
              <h3 className="basic-text fw-bold">Website:</h3>
              <a href={homepageUrl} title="Fashion Worx">
                {config.siteUrl.replace("https://", "")}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="text-center text-lg-start">
        <Row className="mb-5">
          <Col>
            <div className="decorated-header inline">
              <PrismicRichText field={headerLocation.richText} />
            </div>
          </Col>
        </Row>
      </Container>
      <GatsbyImage
        image={imageLocation.gatsbyImageData}
        alt={imageLocation.alt || ""}
        className={
          imageMobileLocation?.gatsbyImageData ? "d-none d-md-block" : null
        }
      />
      <GatsbyImage
        image={imageMobileLocation.gatsbyImageData}
        alt={imageMobileLocation.alt || ""}
        className="d-md-none"
      />
    </Layout>
  );
};

export const query = graphql`
  query contactpageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicContact(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title {
          richText
          text
        }
        image {
          alt
          gatsbyImageData(placeholder: BLURRED)
          dimensions {
            height
            width
          }
          url
        }
        image_mobile {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        header_form {
          richText
        }
        header_location {
          richText
        }
        image_location {
          alt
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        image_mobile_location {
          alt
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;

export default Contact;
